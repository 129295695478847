import React, { useState, useEffect, memo } from 'react';
import classNames from 'classnames';
import Avatar from 'react-avatar-edit';
import { SelectBox } from '../../reactcomponents/SelectBox';
import BriefErrand, { NoDataBriefErrand } from './BriefErrand';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import FileUploader from './FileUploader';
import {
	DataProtectionCtnr
} from '../../views/v5/errandCtnrs';
import {I} from '../../common/v5/config.js';
import { SerialTagList } from './Attachments';
import { SipDialerCtnr } from '../../containers/call'
import { humanByteSize, getNameFromID, getValueFromID } from '../../common/v5/helpers.js';
import {
	CB_ALL_CONTACT,
	CB_EXPERT_IN_AREA,
	CB_CONTACT_CARD,
	CB_AGENTS,
	CB_ADDRESS_BOOK,
	UNSELECT
} from '../../common/v5/constants';
import {
	SIP_CALL_CONNECTED,
	SIP_OUTGOING_CALL_CONNECTED,
	SIP_CALL_ON_HOLD
} from '../../common/v5/callConstants';
import { PortalModalBox } from '../../reactcomponents/Modal';
// import ContactCardsNotes from '../../views/v5/contactcards/contactCardsNotes'; //circular dependency, so need to import this here
import ContactCardsEditForm from '../../views/v5/contactcards/contactCardsEdit';
import { composeWithDisplayName, withUnmountWhenHidden } from '../../reactcomponents/hocs';
import { TableIconicButton } from '../../reactcomponents/Form';
export class ContactBook extends React.Component {
	constructor(props){
		super(props);
		this.handleCloseContactBook = this.handleCloseContactBook.bind(this);
		this.handleAddToContactCard = this.handleAddToContactCard.bind(this);
		this.handleInsertContact = this.handleInsertContact.bind(this);
		this.handleSearchInput = this.handleSearchInput.bind(this);
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
		this.handleCurrentTab = this.handleCurrentTab.bind(this);
		this.handleAttendedTransfer = this.handleAttendedTransfer.bind(this);
		this.handleUnattendedTransfer = this.handleUnattendedTransfer.bind(this);

		this.state = {
			callString: ""
			, isManual: false
			, isAgentSelected: false
		}
	}
	handleCloseContactBook(e){
		this.props.onToggle(false);
	}
	handleInsertContact(){
		const {activeMainView, personDetails, currentTab,
			selectedContact,  ...props} = this.props;
		if(currentTab === CB_CONTACT_CARD) {
			this.props.onReplyAdddress(selectedContact.id
				, selectedContact.address);
			this.props.onToggle(false);
		} else {
			this.props.onReplyAdddress(personDetails.id
				, personDetails.address, activeMainView);
			this.props.onToggle(false);
		}
	}
	handleShowPersonDetails(id, sipid){
		const {currentTab, onShowPersonDatails, isSIPForward, sipCallStatus, sipServerName} = this.props;
		if(isSIPForward) {
			let callString = "", isManual = false;
			if(sipCallStatus != SIP_CALL_CONNECTED &&
				sipCallStatus != SIP_OUTGOING_CALL_CONNECTED &&
				sipCallStatus != SIP_CALL_ON_HOLD){
				console.info("sip call is not connected or on hold. cannot transfer");
				return;
			}
			if (typeof id === "number") {
				id = id.toString();
			}
			if(this.props.manualEid != 0 && !this.props.sipMakeCallCurrentErrand) {
				isManual = true;
			}
			if(typeof sipid !== "undefined") {
				if(id != 0 && sipid != ""){
					if(sipid.startsWith("sip:") == false){
						callString = "sip:";
					}
					callString += sipid;
					if(sipid.includes("@") == false){
						callString += "@" + sipServerName;
					}
				} else {
					return;
				}
				this.setState({
					callString: callString,
					isManual: isManual,
					isAgentSelected: true
				});
			} else {
				//transfer to external
				//we need a better way to differenciate agent and external ?
				if(id !== "" ) {
					if(id.startsWith("sip:") == false){
						callString = "sip:";
					}
					callString += id;
					if(id.includes("@") == false){
						callString += "@" + sipServerName;
					}
				} else {
					console.info("id is blank");
					return;
				}
				this.setState({isAgentSelected: false, callString: callString, isManual: isManual});
			}
		}
		onShowPersonDatails(currentTab, id);
	}
	handleSelectContactEntry(v) {
		const {serviceType, onSelectedContact} = this.props;
		if(v.serviceType === serviceType) {
			onSelectedContact(v.id);
		}
	}
	handleAddToContactCard(){
		this.props.onAddContact();
	}
	handleAttendedTransfer(){
		let isExternalForward = false, cipherKey = this.props.mCipherKey;
		this.props.onToggle(false);
		if(!this.state.isAgentSelected) {
			isExternalForward = true;
		}
		let eid = this.props.errandId;
		if(eid == 0 && this.props.manualEid != 0){
			eid = this.props.manualEid
		}
		let displayId = this.props.displayId;
		if(displayId == "" && this.props.manualDispId != ""){
			displayId = this.props.manualDispId
		}
		if(this.props.sipMakeCallCurrentErrand) {
			cipherKey = this.props.currentCipherKey;
		} else if(this.props.currentSipErrandId == 0){
			if(!features["sip.auto-create-errand"] && this.props.manualEid === 0) {
				eid = 0;
			}
		}
		let targetNumber = this.state.callString;
		if(isExternalForward) {
			if(this.props.selectedContact) {
				if(this.props.selectedContact.address != ""){
					let extContact = this.props.selectedContact.address
					targetNumber = "";
					if(extContact.startsWith("sip:") == false){
						targetNumber = "sip:";
					}
					targetNumber += extContact;
					if(extContact.includes("@") == false){
						targetNumber += "@" + this.props.sipServerName;
					}
				} else {
					return;
				}
			}
		}
		this.props.handleWarmTransferToAgent(eid, this.props.selectedPerson,
			targetNumber, this.props.sipCallConn, displayId,
			cipherKey, this.state.isManual, isExternalForward,
			this.props.extRefId, this.props.areaDialPlan);
		if(features['sip.show-forward-dialer']) {
			this.props.onCloseExternalKeypad();
		}
	}
	handleUnattendedTransfer(){
		let isExternalForward = false, callString = this.state.callString, cipherKey = this.props.mCipherKey;
		this.props.onToggle(false);
		if(!this.state.isAgentSelected) {
			isExternalForward = true;
		}
		let eid = this.props.errandId;
		if(eid == 0 && this.props.manualEid != 0){
			eid = this.props.manualEid
		}
		let displayId = this.props.displayId;
		if(displayId == "" && this.props.manualDispId != ""){
			displayId = this.props.manualDispId;
		}
		if(this.props.sipMakeCallCurrentErrand) {
			cipherKey = this.props.currentCipherKey;
		} else if(this.props.currentSipErrandId == 0) {
			if(!features["sip.auto-create-errand"] && this.props.manualEid === 0) {
				eid = 0;
			}
		}
		let targetNumber = this.state.callString;
		if(isExternalForward) {
			if(this.props.selectedContact) {
				if(this.props.selectedContact.address != ""){
					targetNumber = "";
					let extContact = this.props.selectedContact.address
					if(extContact.startsWith("sip:") == false){
						targetNumber = "sip:";
					}
					targetNumber += extContact;
					if(extContact.includes("@") == false){
						targetNumber += "@" + this.props.sipServerName;
					}
				} else {
					return;
				}
			}
		}
		this.props.handleColdTransferToAgent(eid, this.props.selectedPerson,
			targetNumber, this.props.sipCallConn, displayId,
			cipherKey, this.state.isManual, isExternalForward,
			this.props.extRefId, this.props.areaDialPlan);
		if(features['sip.show-forward-dialer']) {
			this.props.onCloseExternalKeypad();
		}
	}
	handleSearchInput(e){
		this.props.onSetSearchText(e.target.value);
	}
	handleSearchSubmit(e){
		const p = this.props;
		p.onHandleSearch(p.show
			, p.currentTab
			, p.searchText);
		e.preventDefault();
	}
	handleCurrentTab(type){
		const p = this.props;
		p.onHandleCurrentTab(type)
	}
	render() {
		const {personList, selectedPerson, contactList
			, replyType, currentTab, personDetails: person
			, selectedContact, isAgents, isContactCard
			, isCollaborator, error, isAddToContactCard
			, searchText, isShare, isSIPForward, show, ...props} = this.props;
		let customerList = []
			, contacts = []
			, customerInfo = ""
			, errorMessage = ""
			, btnDisable = true
			, contactButton = ""
			, transferButtons = [];
		let cbClass = "contact-book-backdrop popup";
		if(show) {
			cbClass = "contact-book-backdrop popup open";
		}
		$.each(personList, (i,v) => {
			customerList.push(
				<li key={"index_"+i}
					className={v.id === selectedPerson ? "current":""}>
					<a data-qa-id={"cbook-list-"+v.name} onClick={(e) => this.handleShowPersonDetails(v.id, v.sipid)}>
						{v.name}
					</a>
				</li>
			);
		});
		if(person) {
			let showExtId = false;
			if(typeof person.externalId !== "undefined" && person.externalId !=
				""){
				showExtId = true;
			}
			customerInfo = (
				<div className="user-card">
					<div className="user-avatar">
						<ProfilePhoto photo={person.photo}
							width={"45"} isAgent={false}
							className={"conversation-photo"} />
					</div>
					<div className="user-data">
						<div className="user-name">
							{person.name}
						</div>
						{
							person.companyName &&
								<div className="user-title">{person.companyName}</div>
						}
						{showExtId && <div className="user-extid">
							({person.externalId})
						</div>
						}
					</div>
				</div>
			);
			if(currentTab === CB_CONTACT_CARD) {
				$.each(contactList, (i,v) => {
					contacts.push(
						<li className={v.id === selectedContact.id ? "current":""}
							key={v.id}>
							<a onClick={(e) => this.handleSelectContactEntry(v)}>
								<div className="contacts-entry">
									<div className="contacts-service">
										{v.service}
									</div>
									<div className="contacts-address">
										{v.address}
									</div>
								</div>
							</a>
						</li>
					);
				});
			} else {
				contacts.push(
					<li className={selectedContact.id !== 0 ? "current":""}
						key={person.id}>
						<a href="#">
							<div className="contacts-entry">
								<div className="contacts-service">
									{person.service}
								</div>
								<div className="contacts-address">
									{person.address}
								</div>
							</div>
						</a>
					</li>
				);
			}
		}
		if(error !== "") {
			btnDisable = true;
			errorMessage = (
				<div className="value-block">
					<font color="red">{error}</font>
				</div>
			);
		}
		if(isAddToContactCard) {
			btnDisable = ((selectedPerson > 0 && selectedPerson !== "") ? false : true); // && ??
			contactButton = (
				<button className="btn-blue" disabled={btnDisable} title={I("Add to this contact card")}
					onClick={this.handleAddToContactCard}>
						{I("Add to this contact card")}
				</button>
			);
		} else if(isSIPForward) {
			btnDisable = ((selectedPerson > 0 && selectedPerson !== "") ? false : true); // && ??
			if(this.props.sipHideWarmTransfer !== true){
				transferButtons.push(
					<button key="sip-transfer-attended" data-qa-id="sip-transfer-attended" className="btn-blue" disabled={btnDisable}
						title={I("Attended transfer")}
						onClick={this.handleAttendedTransfer}>
							{I("Attended transfer")}
					</button>
				);
			}
			if(this.props.sipHideColdTransfer !== true){
				transferButtons.push(
					<button key="sip-transfer-unattended" data-qa-id="sip-transfer-unattended" className="btn-blue" disabled={btnDisable}
						title={I("Unattended transfer")}
						onClick={this.handleUnattendedTransfer}>
							{I("Unattended transfer")}
					</button>
				);
			}
		} else {
			btnDisable = (selectedContact.id !== 0 ? false : true);
			contactButton = (
				<button className="btn-blue" disabled={btnDisable} title={I("Insert")}
					onClick={this.handleInsertContact}>
						{I("Insert")}
				</button>
			);
		}
		
		if(show) {
			return (
				<div className={cbClass}>
					<div className="contact-book popup-inner">
						<div className="contact-book-sidebar">
							<div className="popup-title">
								<h2>{I("Contact book")}</h2>
							</div>
							<div className="contact-book-menu">
								<ul>
									{isAgents || isCollaborator || isContactCard  || isSIPForward ?
										<li className={currentTab === CB_ALL_CONTACT ? "current":""}>
											<a href="#"
												onClick={(e) => this.handleCurrentTab(CB_ALL_CONTACT)}>
													{I("All contacts")}
											</a>
										</li> : ""
									}
									{isCollaborator || isSIPForward ?
										<li className={currentTab === CB_EXPERT_IN_AREA ? "current":""}>
											<a href="#"
												onClick={(e) => this.handleCurrentTab(CB_EXPERT_IN_AREA)}>
													{I("Experts in Area")}
											</a>
										</li> : ""
									}
									{isAgents || isShare || isSIPForward ?
										<li className={currentTab === CB_AGENTS ? "current":""}>
											<a href="#"
												onClick={(e) => this.handleCurrentTab(CB_AGENTS)}>
													{I("Agents")}
											</a>
										</li> : ""
									}
									{(isCollaborator || isContactCard || isSIPForward) ?
										<li className={currentTab === CB_ADDRESS_BOOK ? "current":""}>
											<a href="#"
												onClick={(e) => this.handleCurrentTab(CB_ADDRESS_BOOK)}>
													{I("Address book")}
											</a>
										</li> : ""
									}
									<li className="separator"></li>
									{isCollaborator || isContactCard || isAddToContactCard || isSIPForward ?
										<li className={currentTab === CB_CONTACT_CARD ? "current":""}>
											<a href="#"
												onClick={(e) => this.handleCurrentTab(CB_CONTACT_CARD)}>
													{I("Contact cards")}
											</a>
										</li> : ""
									}
								</ul>
							</div>
							<div>
								<SipDialerCtnr dialerClass={"sip-ext-dialer"} />
							</div>
						</div>
						<div className="contact-book-contacts">
							<div className="contact-book-search">
								<form onSubmit={this.handleSearchSubmit}>
									<button><i className="icon-search"></i></button>
									<input className="search-field" type="text"
										value={searchText}
										placeholder={I('Search')}
										onChange={this.handleSearchInput}
									/>
								</form>
							</div>
							<div className="contact-book-list" data-custom-scrollbar>
								<ul>
									{customerList}
								
								</ul>
							</div>
						</div>
						<div className="contact-book-content">
							<div className="contact-book-content-header">
							</div>
							<div className="contact-book-content-body">
								{customerInfo}
								<div className="add-information">
									<div className="card-list">
										<ul>
											{contacts}
										</ul>
									</div>
									{errorMessage}
								</div>
							</div>
							<div className={classNames(
								'button-row',
								{ 'multiple': isSIPForward}
							)}>
								{ !isSIPForward && contactButton }
								{transferButtons}
							</div>
						</div>
						<div className="popup-close" onClick={this.handleCloseContactBook}>
							<i className="icon-times"></i>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export class ContactCard extends React.Component {
	constructor(props) {
		super(props);
		this.handleCloseContactCard = this.handleCloseContactCard.bind(this);
		this.handleShowHistory = this.handleShowHistory.bind(this);
		this.handleHideHistory = this.handleHideHistory.bind(this);
		this.handleToggleChannel = this.handleToggleChannel.bind(this);
		this.handleSelectedChannel = this.handleSelectedChannel.bind(this);
		this.handleSelectAnswered = this.handleSelectAnswered.bind(this);
		this.handleSelectUnanswered = this.handleSelectUnanswered.bind(this);
		this.handleInputContact = this.handleInputContact.bind(this);
		this.handleCustomLabel = this.handleCustomLabel.bind(this);
		this.handleAddContact = this.handleAddContact.bind(this);
		this.handleInputNote = this.handleInputNote.bind(this);
		this.handleAddNote = this.handleAddNote.bind(this);
		this.handleRemoveNote = this.handleRemoveNote.bind(this);
		this.handleAddToExistingCard = this.handleAddToExistingCard.bind(this);
		this.handleShowMore = this.handleShowMore.bind(this);
		this.handleShowLess = this.handleShowLess.bind(this);
		this.handleAvatarCrop = this.handleAvatarCrop.bind(this);
		this.handleSaveAvatar = this.handleSaveAvatar.bind(this);
		this.handleRemoveAvatar = this.handleRemoveAvatar.bind(this);
		this.handleAttachments = this.handleAttachments.bind(this);
		this.handleCardExpand = this.handleCardExpand.bind(this);
		this.handleEditName = this.handleEditName.bind(this);
		this.handleSetName = this.handleSetName.bind(this);
		this.handleSetNameByEnter = this.handleSetNameByEnter.bind(this);
		this.handleAnonymizeErrand = this.handleAnonymizeErrand.bind(this);
		this.handleToggleCompany = this.handleToggleCompany.bind(this);
		this.handleSetCompany = this.handleSetCompany.bind(this);
		this.handleShowCompanyInfo = this.handleShowCompanyInfo.bind(this);
		this.handleHideCompanyInfo = this.handleHideCompanyInfo.bind(this);
		this.state = {
			expand: true,
			editName: false,
			companyChanged: false,
			showCompanySelect: false
		}
	}
	componentDidUpdate(){
		var el = this.refs.noteList;
		if(el){
			el.scrollTop = el.scrollHeight;
		}
	}
	handleCloseContactCard(){
		this.props.onToggle(false);
		this.setState({
			expand: true //to reset back to expand once close
		})
	}
	handleShowHistory(){
		this.props.onShowHistory(true);
		if(this.props.showCompanyInfo){
			this.handleHideCompanyInfo();
		}
	}
	handleHideHistory(){
		this.props.onShowHistory(false);
		if(this.props.showCompanyInfo){
			this.props.onShowCompanyInfo(false);
			this.handleHideCompanyInfo();
		}
	}
	handleToggleChannel(tgl){
		this.props.onShowChannelOptions(tgl);
	}
	handleSelectedChannel(selectedChannel){
		let clType = 0;
		$.each(this.props.channel, (k,v) => {
			if(v.id === selectedChannel){
				clType = v.type;
				return false;
			}
		});
		this.props.onSetContactCard("selectedChannel", selectedChannel);
		this.props.onSetContactCard("channelType", clType);
		this.props.onShowChannelOptions(!this.props.showChannels);
	}
	handleRemoveContact(id){
		this.props.onRemoveContact(id);
	}
	handleInputContact(e){
		this.props.onSetContactCard("contactInput", e.target.value);
	}
	handleCustomLabel(e){
		this.props.onSetContactCard("customLabel", e.target.value);
	}
	handleInputNote(e){
		if(e.target.value != ""){
			this.props.onSetContactCard("notesButton", false);
		} else {
			this.props.onSetContactCard("notesButton", true);
		}
		this.props.onSetContactCard("noteText", e.target.value);
	}
	handleAddContact(e){
		e.preventDefault();
		const p = this.props;
		const cc = p.contactCard;
		const customer = p.customer;

		if(customer && cc) {
			if(cc.name !== "") {
				p.onAddContact();
			} else {
				this.props.onSetContactCard("name", customer.name);
				p.onAddContact();
			}
		}
		this.setState({companyChanged: false});
	}
	handleAddNote(){
		this.props.onAddNote();
	}
	handleRemoveNote(id){
		this.props.onRemoveNote(id)
	}
	handleAddToExistingCard(){
		this.props.onAddToExistingCard(true);
		this.handleCloseContactCard();
	}
	handleShowMore(){
		this.props.onSetContactCard("showMore", true);
	}
	handleShowLess(){
		this.props.onSetContactCard("showMore", false);
	}
	handleSelectAnswered(checked){
		this.props.onSetContactCard("checkAnsweredHistory", checked);
	}
	handleSelectUnanswered(checked){
		this.props.onSetContactCard("checkUnansweredHistory", checked);
	}
	handleAvatarCrop(preview){
		this.props.onSetContactCard("avatarPreview", preview);
	}
	handleSaveAvatar(){
		this.props.onSaveAvatar();
	}
	handleAttachments(dataFiles, uploadTo){
		this.props.onFileupload(dataFiles[0], uploadTo);
	}
	handleRemoveAvatar(){
		this.props.onRemoveAvatar();
	}
	handleOpenErrand(id, threadId){
		this.props.onShowOtherContact(id, threadId);
	}
	handleCardExpand(){
		this.setState({expand: !this.state.expand});
	}
	handleEditName(){
		this.setState({editName: !this.state.editName});
	}
	handleSetName(e){
		this.props.onSetContactCard("name", e.target.value);
	}
	handleToggleCompany(tgl){
		this.setState({showCompanySelect: tgl});
	}
	handleSetCompany(id){
		let name = getNameFromID(id, this.props.compList);
		let custComp = { companyId: id, companyName: name };
		this.props.onChangeCompany("companyId", custComp);
		this.setState({showCompanySelect: false, companyChanged: true});
	}
	handleShowCompanyInfo(){
		this.props.onShowCompanyInfo(true);
		this.handleShowHistory();
		if(this.props.onGetCompanyHistory) {
			this.props.onGetCompanyHistory(this.props.currentErrandId);
		}
	}
	handleHideCompanyInfo(){
		this.props.onShowCompanyInfo(false);
	}
	handleSetNameByEnter(e){
		const p = this.props;
		const cc = p.contactCard;
		const customer = p.customer;
		if(e.keyCode == 13) {
			this.setState({editName: !this.state.editName});
			if(customer && cc.name !== "" &&
				customer.name !== cc.name) {
				p.onAddContact();
			}
		}
	}
	handleAnonymizeErrand(e, id, cipherKey){
		var ok = window.confirm(I("You are deleting the content of this errand. If you proceed there is no way of reversing the action"));
		if(ok) {
			$.post(webserverRoot + "contact/anonymizeErrand", {
				errandId: id,
				cipher_key: cipherKey
			})
			.done(function(rs){
				if (rs && rs.result) {
					if (rs.result.id === 0 && rs.result.error != "") {
						this.props.onShowAlert(rs.result.error)
					} else {
						this.props.refreshOtherContacts(this.props.currentErrandId);
					}
				}
			}.bind(this));
		}
	}
	render() {
		const p = this.props;
		const cc = p.contactCard;
		let classShrink="", editIcon = "", contactCardName = p.customer.name;
		let ccClass = "contact-card-popup popup", hideHistory = true, hideCard = false;
		let clist = [], customerSince = "", customerNotes = [], date2 = new Date();
		let addToContactCard = "", otherContacts = [], showMore = "", errorMessage = "";
		let avatarPreview = "", hidelabel = true, setProfilePic = "", uploadAvatar = "";
		let noteAttachment = [], additionalInfo = "", addContainerHeight="60%";
		let compEmail = "", compContact = "";

		if(cc.show) {
			ccClass = "contact-card-popup popup open";
		}
		if(!this.state.expand){
			classShrink = "shrinked";
		}
		if(cc.showContactHistory){
			hideHistory = false;
			hideCard = true;
		}
		if(p.showCompanyInfo){
			compEmail = I("Email") +" : "+ getValueFromID(p.customer.companyId, "email", p.compList);
			compContact = I("Contact") +" : "+ getValueFromID(p.customer.companyId, "phone", p.compList);
		}
		if(p.customer.id === 0){
			addToContactCard = (
				<a href="#" onClick={this.handleAddToExistingCard}
					className="add-contact">
					{I("Add to existing contact card?")}
				</a>
			);
		}
		if(p.customer.date != ""){
			customerSince = I("Contact since") + " " + p.customer.date;
		}
		if(p.customer.postcode != "") {
			additionalInfo = p.customer.postcode;
		}
		if(p.customer.city != "") {
			additionalInfo += ", " + p.customer.city;
		}
		$.each(p.contactList, (i,v) =>{
			clist.push(
				<tr key={i}>
					<td data-qa-id={"QA_cc_list_service"}>{v.service}</td>
					<td data-qa-id={"QA_cc_list_address"}>{v.address}</td>
					<td>
						<a data-qa-id={"QA_cc_remove_"+v.service}
							onClick={(e) => this.handleRemoveContact(v.id)}>
							<i className="icon-circled-times"></i>
						</a>
					</td>
				</tr>
			);
		});
		$.each(p.customerNotes, (i,v) =>{
			let created = "", t = 0, attachments = [];
			let date1 = new Date(v.writtenDate);
			let timeDiff = Math.abs(date2.getTime() - date1.getTime());
			let diffDays = timeDiff / (1000 * 3600 * 24);
			if((diffDays % 1) >= 0.5) {
				t = Math.ceil(diffDays);
			} else {
				t = Math.floor(diffDays);
			}
			if(t == 0) {
				created = I("today");
			} else {
				created = t + " " + I("days ago");
			}
			$.each(v.attachments, (i,k) =>{
				attachments.push(
					<span className="customer-note-attachment" key={i}>
						<i className={'fa fa-paperclip file-icon'}
							aria-hidden="true" />
						<a className='link' href={k.downloadURL}
							target='_blank'
							data-qa-id={"attachment-"+k.fileName}
							onClick={this.handleClick}>
							{k.fileName}
						</a>
						({humanByteSize(k.estSize)})
					</span>
				);
			});
			customerNotes.push(
				<div className="note-item" key={"client-note-"+i+"-"+v.id} data-qa-id={"QA_cc_note"+v.id}>
					<p>{v.note}</p>
					<p>{attachments}</p>
					<div className="note-author">{I("Written by")} {v.username}, {created}
						<div className="pull-right">
						{features["notes.client.agent-edit"] &&
							<a data-qa-id={"QA_cc_remove_note_"+v.id}
								onClick={(e) => this.handleRemoveNote(v.id)}>
								<i className="icon-circled-times"></i>
							</a>
						}
						</div>
					</div>
				</div>
			)
		});
		$.each(p.otherContacts.order, (i, v) => {
			let oco = p.otherContacts.norm[v], oc = oco.data;
			let subject = (oc.iconicSubject != "" ? oc.iconicSubject : oc.subject);
			let short = subject;
			if(subject.length > 35) {
				short = subject.substring(0,35)+'...';
			}
			if(cc.checkAnsweredHistory &&
					!cc.checkUnansweredHistory && !oc.answered){
				return true;
			}
			if(!cc.checkAnsweredHistory &&
					cc.checkUnansweredHistory && oc.answered) {
				return true;
			}
			if(otherContacts.length === 18 && !cc.showMore){ return false; }
			otherContacts.push(
				<tr key={i}>
					<td>{oc.date}</td>
					<td>{oc.serviceName}</td>
					<td>
						<a className="current"
							onClick={(e) => this.handleOpenErrand(oc.id, oco.threadId)}>
								{oc.displayId}
						</a>
					</td>
					<td>{oc.agent}</td>
					<td><div title={subject} dangerouslySetInnerHTML={{__html: short}} /></td>
					<td><button data-qa-id={"QA_anonymize_errand"+i} title={I('Anonymize errand')} className="btn-red slim" type="button" hidden={!p.agentAnonymize || oc.anonymized}
						onClick={(e) => this.handleAnonymizeErrand(e, oc.id, oc.cipherKey)}>Anonymize errand</button></td>
				</tr>
			);
		});
		if(p.otherContacts.order.length > 18
				&& otherContacts.length == 18 && !cc.showMore) {
			showMore = (
				<div className="center">
					<a className="show-more"
						onClick={this.handleShowMore}>
						{I("Show more")}
					</a>
				</div>
			);
		} else if(otherContacts.length > 18 && cc.showMore){
			showMore = (
				<div className="center">
					<a className="show-more" onClick={this.handleShowLess}>
						{I("Show less")}
					</a>
				</div>
			);
		}
		if(cc.error != ""){
			errorMessage = (
				<div className="value-block">
					<font color="red">{cc.error}</font>
				</div>
			);
		}
		if(cc.channelType === 0){ //any service
			hidelabel = false;
		}
		if(cc.avatarPreview != ""){
			avatarPreview = (
				<img src={cc.avatarPreview} />
			);
			if(cc.avatarPreview.length > 200){
				setProfilePic = (
					<div style={{textAlign: 'center'}}>
						<span>{I('Set')}</span>
						<span>
							{/* <input type="checkbox" defaultChecked={false}
								onChange={this.handleSaveAvatar} /> */}
							<SquareCheckbox onClick={this.handleSaveAvatar}
								id="Save-Avatar"
								checked={false}/>
						</span>
					</div>
				);
			}
		}
		uploadAvatar = (
			<div className="upload-photo">
				<div className="add-information-title">
					{I("Upload customer profile picture")}
				</div>
				<div className="label-block">
					<Avatar
						label={I('Choose a file')}
						className="upload-avatar"
						width={120}
						height={100}
						onCrop={this.handleAvatarCrop}
						onClose={this.handleAvatarClose}
						mimeTypes="image/jpeg,image/png,image/gif"
						src={""}
					/>
				</div>
				<div className="value-block avatar-image">
					<div className="avatar-image-preview">
						{avatarPreview}
					</div>
					<div className="avatar-set-photo">
						{cc.avatarPreview != "" && <span className="pull-right">
						{setProfilePic}
						<div title={I("Remove avatar")}><button className="btn-blue" type="button" onClick={this.handleRemoveAvatar}>X</button></div>
						</span>}
					</div>
				</div>
			</div>
		)
		if(cc.show && (p.agentAnonymize || p.dataPortabilityExport) && !p.isChat){
			// addContainerHeight = "35%"; //dont see the need to shrink for now
		}
		if(features["edit-contactcard-name"] &&
			p.customer.name !== "") {
			editIcon = (
				<span>
					<a data-qa-id="QA_cc_edit_card_name"
						onClick={this.handleEditName}>
						<i className="fa fa-pencil"
							aria-hidden="true"
							style={{color:'black'}}>
						</i>
					</a>
				</span>
			);
		}
		if(this.state.editName) {
			contactCardName = (
				<input type="text" style={{height:"25px", width:"107px"}}
					data-qa-id={"QA_cc_inputName"}
					onChange={this.handleSetName}
					value={cc.name}
					onKeyDown={this.handleSetNameByEnter}
				/>
			);
		}
		var extId;
		if(p.customer.externalId !== ""){
			extId = (
				<div className="card-addInfo">{I("External Id: {ID}")
					.replace('{ID}', p.customer.externalId)}</div>
			);
		}
		return (
			<div className={ccClass}>
				<div className="popup-inner">
					<div className="full-width" hidden={hideCard}>
						<div className="contact-card-header">
							<div className="card-img">
								<ProfilePhoto photo={p.customer.photo}
									width={"43"} isAgent={false}
									className={"conversation-photo"} />
							</div>
							<div className="card-data">
								<div data-qa-id={"QA_edited-card_name"} className="card-name">
									{contactCardName}{editIcon}
									{" "}
									{addToContactCard}
								</div>
									{p.customer.companyName &&
										<div className="card-company-wrapper">
											<div className="card-company" title={I("Show company info")} onClick={this.handleShowCompanyInfo}>
												{p.customer.companyName}
											</div>
										</div>
									}
								<div className="card-date">{customerSince}</div>
								<div className="card-addInfo">{additionalInfo}</div>
								{extId}
							</div>
							<a className="card-header-btn open-list"
								data-qa-id={"QA_cc_openHistory"} onClick={this.handleShowHistory}>
									<i className="icon-member-repeat"></i>
							</a>
						</div>
						<div className="contact-card-content">
							<div className="notes">
								<h3>{I("Notes")}</h3>
								<div className="note-list" ref="noteList">
									{customerNotes}
								</div>
								{features["notes.client"] &&
								<div className="add-note">
									<h4>{I("Add note to contact card")}</h4>
									<textarea value={cc.noteText} data-qa-id={"QA_cc_addNote"}
										onChange={this.handleInputNote}>
									</textarea>
									<div className="pull-left uploaded-note-attachment">
										<SerialTagList value={p.noteAttachment}
											showDelete={true}
											onDelete={p.onAttachmentDelete} />
									</div>
									<div className="pull-right">
										<FileUploader
											cssClass="btn btn-file"
											title={I("Upload file")}
											uploadTo="errand/notes/uploadAttachment"
											attachSingle={true}
											multipleFile={false}
											uploadAttachment={this.handleAttachments}
										/>
										<button className="btn-blue" disabled={cc.notesButton}
											data-qa-id={"QA_cc_addNote_btn"}
											onClick={this.handleAddNote}>
											{I("Add")}
										</button>
									</div>
								</div>
								}
							</div>
							<div className="add-information">
								<div className="add-information-container">
									<div className="add-information-title">{I("Add this information to contact card?")}</div>
										<div className="label-row">
											<div className="label-block company-edit-wrapper">
												<div className="add-information-title">
													{I("Company")}
												</div>
												<div className="label-row company-select">
													<SelectBox
														maxHeight='172px'
														className="sidebar-nav-select"
														id={"QA_cc_selectCompany"}
														textNoItemSelected={I('Company')}
														visible={true}
														selected={p.customer && p.customer.companyId ? p.customer.companyId : UNSELECT}
														onSelect={this.handleSetCompany}
														onToggleShow={this.handleToggleCompany}
														show={this.state.showCompanySelect}
														options={p.compList} />
												</div>
												<div className="label-row company-add-btn">
													<button disabled={!this.state.companyChanged} className="btn-blue" data-qa-id={"QA_cc_addCompanyBtn"}
														onClick={this.handleAddContact}>
														{I("Save")}
													</button>
												</div>
											</div>
											<div className="clearfix"></div>
										</div>
									<div className="label-type">
										<div className="label-row">
											<div className="label-block" style={{zIndex: "1000"}}>
												<SelectBox top='26px' maxHeight='172px'
													className="sidebar-nav-select"
													id={"QA_cc_selectChannel"}
													textNoItemSelected={I('Channel')}
													visible={true}
													selected={cc.selectedChannel}
													onSelect={this.handleSelectedChannel}
													onToggleShow={this.handleToggleChannel}
													show={cc.showChannelOptions} options={p.channel} />
											</div>
										</div>
										<div className="label-row">
											<div className="value-block" hidden={hidelabel}>
												<input type="text" placeholder={I("Label")}
													value={cc.customLabel}
													data-qa-id={"QA_cc_customInputLabel"}
													onChange={this.handleCustomLabel}/>
											</div>
											<div className="value-block">
												<input type="text" placeholder={I("Value")} style={{height:"32px", lineHeight:"28px"}}
													value={cc.contactInput}
													data-qa-id={"QA_cc_inputValue"}
													onChange={this.handleInputContact} />
											</div>
											{errorMessage}
										</div>
										<div className="clearfix"></div>
										<div className="label-row">{uploadAvatar}</div>
										<button className="btn-blue" data-qa-id={"QA_cc_addContactBtn"}
											onClick={this.handleAddContact}
											disabled={p.addButton}>
											{I("Add")}
										</button>
									</div>
									<div className="clearfix"></div>
									<div className="add-information-title">
										{I("Address list")}
									</div>
									<div className={"card-list "+ classShrink}>
										<table>
											<tbody>
												{clist}
											</tbody>
										</table>
									</div>
								</div>
								{/*<div className="card-info">
									<table>
										<tbody>
											<tr>
												<td>Customer ID</td>
												<td>234564-635842</td>
												<td><a href=""><i className="icon-circled-times"></i></a></td>
											</tr>
											<tr>
												<td>Socail security number</td>
												<td>245734-2375</td>
												<td><a href=""><i className="icon-circled-times"></i></a></td>
											</tr>
										</tbody>
									</table>
								</div> - todo when upload customer photo*/
								}
								{
									(cc.show && (p.agentAnonymize || p.dataPortabilityExport) && !p.isChat) &&
									<DataProtectionCtnr agentAnonymize={p.agentAnonymize} dataPortabilityExport={p.dataPortabilityExport} expand={this.handleCardExpand}/>
								}
							</div>
						</div>
					</div>
					<div className="full-width-second" hidden={hideHistory}>
						<div className="contact-card-header">
							<div className="card-img">
								<ProfilePhoto photo={p.customer.photo}
									width={"43"} isAgent={false}
									className={"conversation-photo"} />
							</div>
							<div className="card-data" data-qa-id={"QA_cc_cardData"}>
								<div className="card-name" data-qa-id={"QA_cc_cardName"} hidden={p.showCompanyInfo}>
									{p.customer.name}
								</div>
									{p.customer.companyName &&
										<div className="card-company-wrapper">
											<div className="card-company" title={I("Hide company info")} onClick={this.handleShowCompanyInfo}>
												{p.customer.companyName}
											</div>
										</div>
									}
								<div className="card-date" data-qa-id={"QA_cc_cardDate"} hidden={p.showCompanyInfo}>
									{customerSince}
								</div>
								<div hidden={!p.showCompanyInfo} className="card-company-info">
									<div className="card-date">{compEmail}</div>
									<div className="card-date">{compContact}</div>
								</div>
							</div>
							<a className="card-header-btn open-card"
								data-qa-id={"QA_cc_openContactCard"}
								onClick={this.handleHideHistory}>
									<i className="iconContactBookBtn"></i>
							</a>
							<div className="center-title">{p.showCompanyInfo ? I("Company errands") : I("Other contacts")}</div>
						</div>
						<div className="contact-card-content">
							<div className="history-list">
								<div className="history-title">
									{I('History ({COUNT})')
										.replace('{COUNT}', p.otherContacts.order.length)}
								</div>
								<label className="conversation-checkbox">
									{I('Unanswered:')} <SquareCheckbox onClick={this.handleSelectUnanswered}
										id="unanswered-history"
										checked={cc.checkUnansweredHistory}/>
								</label>
								<label className="conversation-checkbox">
									{I('Answered:')} <SquareCheckbox onClick={this.handleSelectAnswered}
										id="answered-history"
										checked={cc.checkAnsweredHistory}/>
								</label>
								<table>
									<thead>
										<tr>
											<th>{I('Arrived')}</th>
											<th>{I('Channel')}</th>
											<th>#</th>
											<th>{I('Agent')}</th>
											<th>{I('Subject')}</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{otherContacts}
									</tbody>
								</table>
								{showMore}
							</div>
						</div>
					</div>
					<div className="popup-close" data-qa-id={"QA_cc_closeContactCard"}
						onClick={this.handleCloseContactCard}>
						<i className="icon-times"></i>
					</div>
				</div>
			</div>
			)
	}
}

const ContactCardErrand = composeWithDisplayName(
	"ContactCardErrand",
	// withProps(({show}) => ({hidden: !show})),
	withUnmountWhenHidden,
	memo
)(ContactCardsEditForm);

export const ContactCardv5 = ({
	contactCard
	, agentAnonymize
	, channel
	, compList
	, contactList
	, dataPortabilityExport
	, defaultService
	, view = "default"
	, customer //input
	, isChat
	, show
	, onChangeInput
	, onTextInputChange
	, onSaveContact
	, onAddContactAccount
	, onDeleteContactAccount
	, onRemoveAvatar
	, customerNotes
	, onShowOtherContact
	, onSetContactCard
	, onToggle
	, onAddNote
	, onAddNoteV5
	, onFileupload
	, onAttachmentDelete
	, noteAttachment
	, onClearAttachments
	, onRemoveNoteV5
	, onDeleteContactCard
	, onCloseContactCard
	, onAddToExistingCard
	, otherContacts
	, refreshOtherContacts
	, onShowAlert
	, showMergeContactPopup
	, onToggleMergePopup
	, ...props
}) => {



	let dataSrc= {
		channel: channel,
		customer: customer,
		errandList: otherContacts.errandList,
		otherContacts: otherContacts.norm,
		agentAnonymize: agentAnonymize,
		list: contactList
	}

	const handleTextInputChange = (e) => {
		let field = e.target.name, value = e.target.value;
		// setInput({ ...input, [field]: value });
		onSetContactCard(field, value);
	}

	const handleAddToExistingCard = () => {
		onAddToExistingCard(true);
		onCloseContactCard();
	}

	const handleShowCompanyInfo = (tgl) => {
		props.onShowCompanyInfo(tgl);
		if(props.onGetCompanyHistory) {
			props.onGetCompanyHistory(props.currentErrandId);
		}
	}

	const handleDelete = (id) => {
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			onDeleteContactCard(id);
		}
	}
	return (
		<PortalModalBox
			className={classNames("errand-contact-card", {"extended": showMergeContactPopup})}
			show={show} onClose={onCloseContactCard} {...props} >
			{customer.id === 0 &&
				<div className='heading__add-existing'>
					<a href='#' onClick={handleAddToExistingCard}>{I("Add to existing contact card?")}</a>
					{/* <button >{I("Yes")}</button> */}
				</div>
			}
			{showMergeContactPopup &&
				<TableIconicButton
					id={"backToContactEdit"}
					className={"btn-round-standard btn-transparent"}
					iconClass={"icon-arrow-left"}
					onClick={() => onToggleMergePopup(false)}
					title={I("Back")}
				/>
			}
			{/* <ContactCardsEditForm */}
			<ContactCardErrand
				hidden={!show}
				activeId={customer.id}
				isNew={customer.id <= 0}
				// input={input}
				input={contactCard}
				dataSrc={dataSrc}
				notes={customerNotes}
				defaultService={defaultService}
				companyList={compList}
				onChangeAdminInput={onSetContactCard} //rename prop to be more generic
				onTextInputChange={handleTextInputChange}
				onSave={onSaveContact}
				onAddAccount={onAddContactAccount}
				onDeleteAccount={onDeleteContactAccount}
				onAddNote={onAddNoteV5}
				onFileupload={onFileupload}
				onAttachmentDelete={onAttachmentDelete}
				noteAttachment={noteAttachment}
				onClearAttachments={onClearAttachments}
				onDeleteNote={onRemoveNoteV5}
				onDeleteAvatar={onRemoveAvatar}
				onShowOtherContact={onShowOtherContact}
				onToggleCompanyErrand={handleShowCompanyInfo}
				errandMode={true}
				showCC={show}
				agentAnonymize={agentAnonymize}
				dataPortabilityExport={dataPortabilityExport}
				isChat={isChat}
				refreshOtherContacts={refreshOtherContacts}
				onShowAlert={onShowAlert}
				onDelete={handleDelete}
				disableEditNote={true}
				onToggleMergePopup={onToggleMergePopup}
				showMergeContactPopup={showMergeContactPopup}
				onMergeContactCards={props.onMergeContactCards}
				eid={props.currentErrandId}
				disableAccountTextInput={customer.id === 0} //if new user, we disable to follow source's
				disableAccountSelectInput={customer.id === 0} //if new user, we disable to follow source's
			/>
		</PortalModalBox>
	)
}

// TODO: Once cflag "2023-06-01.CCC-5436.new.errand.contact.cards.gui" is no longer used,
// we can just use <ContactCardv5 /> directly in its "Ctnr" instead of
// using "ContactCardRenderer"
export const ContactCardRenderer = (props) => {
	let ContactCardGUI = <ContactCard {...props} />
	if(cflag.IsActive("2023-06-01.CCC-5436.new.errand.contact.cards.gui")) {
		ContactCardGUI  = <ContactCardv5 {...props} />;
	}
	return (
		<>{ContactCardGUI}</>
	)
}
